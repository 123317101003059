






























































































































































































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import ExchangeCard from '@/components/cards/ExchangeCard.vue'
import {AdapSearchfield} from '@simpli/vue-adap-table'
import FilterNftAssetCollection from '@/components/filters/FilterNftAssetCollection.vue'
import FilterNftAsset from '@/components/filters/FilterNftAsset.vue'
import NftAssetCard from '@/components/cards/NftAssetCard.vue'
import {GMNftAssetCollection} from '@/model/collection/GhostMarket/GMNftAssetCollection'
import {GMNftCollectionCollection} from '@/model/collection/GhostMarket/GMNftCollectionCollection'
import {$} from '@/facade'
import {SortOption} from '@/types/SortOption'
import TopNftsCard from '@/components/cards/TopNftsCard.vue'
import {MyWalletCacheAssist} from '@/helpers/MyWalletCacheAssist'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import FilterNftAssetCollectionMobile from '@/components/filters/FilterNftAssetCollectionMobile.vue'
import {Modal} from '@simpli/vue-modal'

@Component({
  components: {
    TopNftsCard,
    AdapSearchfield,
    FilterNftAssetCollection,
    FilterNftAsset,
    NftAssetCard,
    FilterNftAssetCollectionMobile,
    Modal,
  },
})
export default class MyWalletMyNfts extends MixinScreenSize {
  @Prop({type: MyWalletCacheAssist, required: true})
  cacheAssist!: MyWalletCacheAssist

  showModal = false

  private toggleModal(): void {
    this.showModal = !this.showModal
  }
}
