import {
  HttpExclude,
  RequestExpose,
  ResponseExpose,
} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {GMNftAsset} from '@/model/resource/GhostMarket/GMNftAsset'
import axios from 'axios'
import {$} from '@/facade'
import {EnvHelper} from '@/helpers/EnvHelper'

type FilterGMNftAssetCollection = {
  ownerAddress: string | null
  orderBy: string | null
  orderDirection: string | null
  collectionSlug: string | null
  cursor: string | null
  limit: number | null
  search: string | null
}

@HttpExclude()
export class GMNftAssetCollection extends ExpansibleCollection<GMNftAsset> {
  resource?: IGMNftAssetCollectionResourceHolder

  @RequestExpose() ownerAddress: string | null = null
  @RequestExpose() orderBy: string | null = null
  @RequestExpose() orderDirection: string | null = null
  @RequestExpose() collectionSlug: string | null = null
  @RequestExpose() cursor: string | null = null
  @RequestExpose() limit: number | null = null

  cursors: string[] = []
  currentCursor: string | null = null
  nextCursor: string | null = null
  previousCursor: string | null = null
  currentNFTPage = 1
  existMoreItems = false

  currentFilter: FilterGMNftAssetCollection = {
    ownerAddress: null,
    orderBy: null,
    orderDirection: null,
    collectionSlug: null,
    cursor: null,
    limit: null,
    search: null,
  }

  constructor() {
    super(GMNftAsset)
  }

  async sort(orderDirection: boolean): Promise<void> {
    await this.applyFilter({orderDirection: orderDirection ? 'Asc' : 'Desc'})
  }

  async filterCollection(collectionSlug: string | null): Promise<void> {
    await this.applyFilter({collectionSlug})
  }

  isOnFirstPage(): boolean {
    return this.currentNFTPage === 1
  }

  hasNextPage(): boolean {
    return this.nextCursor !== null && this.existMoreItems
  }

  hasPreviousPage(): boolean {
    return this.currentNFTPage > 1 || this.previousCursor !== null
  }

  async navigateToPage(
    direction: 'first' | 'previous' | 'next'
  ): Promise<void> {
    if (
      (direction === 'previous' && !this.hasPreviousPage()) ||
      (direction === 'next' && !this.hasNextPage())
    ) {
      return
    }

    await $.await.run('filterNfts', async () => {
      if (direction === 'first') {
        this.currentNFTPage = 1
        this.cursor = null
        this.previousCursor = null
        this.currentCursor = null
      } else if (direction === 'previous') {
        this.cursor = this.previousCursor
        this.currentNFTPage--
      } else {
        this.cursor = this.nextCursor
        this.currentNFTPage++
      }

      await this.queryAsPage()
    })
  }

  async queryAsPage(): Promise<void> {
    const params = this.getURLSearchParams()
    const {items, nextCursor} = await this.listNftAssetCollection(params)

    this.items = items
    this.nextCursor = nextCursor
    this.updatePagination(params, nextCursor)

    await this.verifyIfExistMoreItems(params)
  }

  async verifyIfExistMoreItems(params: URLSearchParams): Promise<void> {
    if (this.limit !== this.items.length) {
      this.existMoreItems = false
      return
    }

    if (this.nextCursor) {
      params.delete('cursor')
      params.append('cursor', this.nextCursor)

      const {items} = await this.listNftAssetCollection(params)
      if (items) {
        this.existMoreItems = true
      } else {
        this.existMoreItems = false
      }
    }
  }

  async listNftAssetCollection(params?: URLSearchParams): Promise<any> {
    const url = `${EnvHelper.VUE_APP_BASE_GHOSTMARKET_URL}assets${
      params ? `?${params}` : ''
    }`
    const {data} = await axios.get(url)

    return {
      items: data.assets?.map((asset: any) => {
        return Object.assign(new GMNftAsset(), {
          id: asset.tokenId,
          name: asset.metadata?.name,
          imageUrl: asset.metadata?.mediaUri,
          contractChain: asset.contract?.chain,
          contractHash: asset.contract?.hash,
          collectionName: asset.collection?.name,
          collectionImageUrl: asset.collection?.logoUrl,
        })
      }),
      nextCursor: data.next || null,
    }
  }

  private async applyFilter(
    filter: Partial<FilterGMNftAssetCollection>
  ): Promise<void> {
    await $.await.run('filterNfts', async () => {
      Object.assign(this, filter, {cursor: null})
      await this.queryAsPage()
    })
  }

  private updatePagination(
    params: URLSearchParams,
    nextCursor: string | null
  ): void {
    const filterWithoutCursor = (p: URLSearchParams) => {
      const obj = Object.fromEntries(p.entries())
      delete obj.cursor
      return JSON.stringify(obj)
    }

    if (
      filterWithoutCursor(
        new URLSearchParams(this.filterToSearchParams(this.currentFilter))
      ) !== filterWithoutCursor(params)
    ) {
      this.currentNFTPage = 1
      this.previousCursor = null
      if (nextCursor) {
        this.cursors = [nextCursor]
      }
    } else {
      if (nextCursor) {
        this.cursors.push(nextCursor)
      }

      this.previousCursor =
        this.cursors[this.cursors.indexOf(this.currentCursor!) - 1] || null
    }

    this.currentFilter = (Object.fromEntries(
      params.entries()
    ) as unknown) as FilterGMNftAssetCollection
  }

  private getURLSearchParams(): URLSearchParams {
    const params = new URLSearchParams()
    if (this.cursor) params.append('cursor', this.cursor)
    if (this.orderBy) params.append('orderBy', this.orderBy)
    if (this.limit) params.append('size', String(this.limit))
    if (this.orderDirection)
      params.append('orderDirection', this.orderDirection)
    if (this.collectionSlug && this.collectionSlug !== 'All')
      params.append('collection', this.collectionSlug)
    if (this.search) params.append('name', this.search)
    if (this.ownerAddress) params.append('owners[]', this.ownerAddress)

    params.append('ownersChains[]', 'n3')

    return params
  }

  private filterToSearchParams(
    filter: FilterGMNftAssetCollection
  ): URLSearchParams {
    const params = new URLSearchParams()
    Object.entries(filter).forEach(([key, value]) => {
      if (value !== null) {
        params.append(key, value.toString())
      }
    })
    return params
  }
}

export interface IGMNftAssetCollectionResourceHolder {}
